import { useEffect, useState } from "react";
import type { NextPage } from "next";
import type { DefaultLayoutData, XXLAppData } from "../../global";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { getFullPageTitle } from "../../utils/page-helper";
import Head from "next/head";
import { getHomePageProps } from "../../utils/apis/content-homepage-api";
import { HomepageContent } from "../../components/HomepageContent/HomepageContent";
import { useSharedData } from "../../../../react-app/src/contexts/SharedData";
import { Section } from "../../components/HomepageContent/HomepageContent.styled";
import { withPageData } from "../../utils/common-page-data/common-page-data";
import type { HomePage20, HomePage20ContentModules } from "@xxl/content-api";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { useSymplifyWithSanity } from "@/react-utils/Symplify/hooks";
import { SANITY_TESTS_MAP } from "@/react-utils/Symplify/constants";
import { SYMPLIFY_ORIGINAL_KEY } from "@/utils/constants";

type HomePageProps = {
  siteId: string;
};

const HomePage: NextPage<
  HomePageProps & XXLAppData & DefaultLayoutData & { abTestsPage?: HomePage20 }
> = ({ homepageContent, abTestsPage }) => {
  const { t } = useTranslations();
  const pageTitle = getFullPageTitle(t("home.page.title"), t);
  const metaDescription = t("home.page.meta.description");
  const [pageContent, setPageContent] = useState<HomePage20ContentModules>([]);
  const hasSymplify = useSymplifyWithSanity("HOMEPAGE_SANITY_TEST");
  const {
    configuration: {
      frontendApi: { basePath },
    },
  } = useSharedData().data;
  const robotsContent = "index, follow";

  useEffect(() => {
    if (hasSymplify === true && isNotNullOrUndefined(abTestsPage)) {
      const activeProjects = window.symplify.getActivatedProjects();
      const homePageProject = activeProjects.find((item) =>
        item.projectName
          .toLowerCase()
          .includes(SANITY_TESTS_MAP.CAMPAIGN_PAGE_SANITY_TEST.PROJECT_NAME)
      );
      if (
        isNotNullOrUndefined(homePageProject) &&
        homePageProject.variationName !== SYMPLIFY_ORIGINAL_KEY
      ) {
        setPageContent(abTestsPage.contentModules);
      }
    } else {
      setPageContent(homepageContent);
    }
  }, [abTestsPage, hasSymplify, homepageContent]);
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="robots" content={robotsContent} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={basePath} />
      </Head>
      <Section>
        <HomepageContent contentModules={pageContent} />
      </Section>
    </>
  );
};

export default HomePage;

export const getServerSideProps = withPageData(getHomePageProps);
