import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { HighlightedCategories } from "@/react-components/HighlightedCategories/HighlightedCategories";
import { useMediaQuery } from "@mui/material";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  CampaignCarousel as CampaignCarouselType,
  ContentProductCarousel,
  FourGridBanners,
  FullGridComponent,
  HalfGridComponent,
  HighlightedCategoriesComponent,
  HomePage20ContentModules,
  HomePageCategories,
  HomePageProductRecommendations,
  InternalIdentifier,
  MiniBanner as MiniBannerType,
  QuarterGridComponent,
  TwoColumnBanners,
  TwoColumnGrid,
} from "@xxl/content-api";
import { type RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import React, { useEffect, useState } from "react";
import { FullGridBanner } from "react-app/src/components/Banners/FullGrid/FullGridBanner";
import { HalfGridBanner } from "react-app/src/components/Banners/HalfGrid/HalfGridBanner";
import { QuarterGridBanner } from "react-app/src/components/Banners/QuarterGrid/QuarterGridBanner";
import { ErrorBoundary } from "react-app/src/components/Common/ErrorBoundary/ErrorBoundary";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { MiniBanner } from "react-app/src/components/MiniBanner/MiniBanner";
import type { MiniBannerNonOptional } from "react-app/src/components/MiniBanner/MiniBanner.helper";
import { ProductList } from "react-app/src/components/PersonalizedProductList";
import {
  ListStyles,
  fetchData as fetchRecommendations,
  type PersonalizedData,
  type Recommendation,
} from "react-app/src/components/PersonalizedProductList/personalized-product-list-helper";
import { PopularCategories } from "react-app/src/components/PopularCategories/PopularCategories";
import type { ProductBannerPricing } from "react-app/src/components/PriceDisplays/price-display-helper";
import {
  BannerSize,
  convertToBannerPricing,
  fetchPriceDisplays,
} from "react-app/src/components/PriceDisplays/price-display-helper";
import { BrandList } from "react-app/src/components/Search/Brands/BrandList";
import { UspComponent } from "react-app/src/components/UspComponent/UspComponent";
import { useApiClients } from "react-app/src/contexts/ApiClients";
import { useSessionSource } from "react-app/src/contexts/Session";
import { useSharedData } from "react-app/src/contexts/SharedData";
import {
  laptopMediaQuery,
  mobileMediaQuery,
  mobileTabletLaptopAndSmallDesktopMediaQuery,
  tabletAndDesktopMediaQuery,
} from "react-app/src/utils/xxl-screen";
import { useRelinkValue } from "react-relink";
import { Guides } from "../ProductDetailsPage/Guides";
import { Heading } from "../ProductDetailsPage/Guides/Guides.styled";
import { getComponentSpacing } from "../ProductDetailsPage/ProductDetailsPage.helper";
import { SiteContainer } from "../common/SiteContainer/SiteContainer";
import {
  AllComponentsWrapper,
  BannerComponentWrapper,
  ComponentWrapper,
} from "./HomepageContent.styled";
import { CampaignCarouselComponent } from "../CampaignCarousel/CampaignCarouselComponent";
import { ContentProductCarouselComponent } from "./components/ContentProductCarousel/ContentProductCarousel";
import { LegacyBanners } from "./components/LegacyBanner/LegacyBanner";
import {
  BREAKPOINTS,
  CONTENT_PRODUCT_CAROUSEL_PREFIX,
  DEFAULT_PRODUCTS_COUNT,
  MODULE_TYPES,
  createProductFetchInfo,
  setBrands,
  setUsps,
  type BrandsMaxAmountProps,
  type UspsMaxAmountProps,
} from "./utils";
import { log } from "@xxl/logging-utils";

type HomepageContentProps = {
  contentModules: HomePage20ContentModules;
};

export const HomepageContent: React.FunctionComponent<HomepageContentProps> = ({
  contentModules,
}) => {
  const isLoggedIn = useRelinkValue(useSessionSource);
  const isMobile = useMediaQuery(mobileMediaQuery);
  const isTablet = useMediaQuery(mobileTabletLaptopAndSmallDesktopMediaQuery);
  const isTabletAndDesktop = useMediaQuery(tabletAndDesktopMediaQuery);
  const [breakpoint, setBreakpoint] = useState(BREAKPOINTS[2]);
  const [priceData, setPriceData] = useState<ProductBannerPricing[] | null>(
    null
  );
  const isLaptopSize = useMediaQuery(laptopMediaQuery);
  const componentSpacing = getComponentSpacing({ isLaptopSize });
  const [recommendations, setRecommendations] = useState<Recommendation[]>();
  const [personalizedData, setPersonalizedData] =
    React.useState<PersonalizedData>();
  const { siteUid } = useSharedData().data;
  const { recommendationsApi, pricesApi } = useApiClients();
  const { t } = useTranslations();

  useEffect(() => {
    if (isMobile && !isTabletAndDesktop) {
      setBreakpoint(BREAKPOINTS[0]);
    } else if (isTablet && isTabletAndDesktop) {
      setBreakpoint(BREAKPOINTS[1]);
    } else {
      setBreakpoint(BREAKPOINTS[2]);
    }
  }, [isMobile, isTablet, isTabletAndDesktop]);
  const hasRecommendations =
    isNotNullOrUndefined(contentModules) &&
    contentModules.find(
      ({ _type }) => _type === MODULE_TYPES.RECOMMENDATIONS
    ) !== undefined;

  const hasContentProductsCarousel =
    isNotNullOrUndefined(contentModules) &&
    contentModules.find(({ _type }) => _type === "ContentProductCarousel") !==
      undefined;

  useEffect(() => {
    if (hasRecommendations || hasContentProductsCarousel) {
      const recommendationsItems: (HomePageProductRecommendations &
        InternalIdentifier)[] = contentModules.filter(
        ({ _type }) => _type === MODULE_TYPES.RECOMMENDATIONS
      );
      const contentProductItems: (ContentProductCarousel &
        InternalIdentifier)[] = contentModules.filter(
        ({ _type }) => _type === MODULE_TYPES.CONTENT_PRODUCT_CAROUSEL
      ) as (ContentProductCarousel & InternalIdentifier)[];
      const recommendationsRequestItems = recommendationsItems.map((item) => ({
        listStyle: isNotNullOrUndefined(item.listStyle)
          ? (item.listStyle.toUpperCase() as ListStyles)
          : ListStyles.HORIZONTAL,
        productsCount: item.productsCount ?? DEFAULT_PRODUCTS_COUNT,
        recommendationsKey: item._key,
        strategy: item.strategy as RecommendationsParametersStrategyEnum,
        brandNames: item.brandNames,
        linkUrl: item.recommendationsLink?.url,
        linkDisplayName: item.recommendationsLink?.displayName,
        campaignIds: item.campaignIds,
        categoryCodes: item.categoryCodes,
        includedProducts: item.productIds,
        days: item.days,
        title: item.title,
      }));

      const contentProductRequestItems = contentProductItems.map((item) => ({
        listStyle: ListStyles.HORIZONTAL,
        productsCount:
          item.productRecommendation?.productsCount ?? DEFAULT_PRODUCTS_COUNT,
        recommendationsKey: `${CONTENT_PRODUCT_CAROUSEL_PREFIX}${item._key}`,
        strategy: item.productRecommendation
          ?.strategy as RecommendationsParametersStrategyEnum,
        brandNames: item.productRecommendation?.brandNames,
        campaignIds: item.productRecommendation?.campaignIds,
        categoryCodes: item.productRecommendation?.categoryCodes,
        includedProducts: item.productRecommendation?.productIds,
      }));

      if (recommendationsItems.length > 0) {
        setRecommendations([
          ...recommendationsRequestItems,
          ...contentProductRequestItems,
        ]);
      }
    }
  }, [contentModules, hasRecommendations]);

  useEffect(() => {
    if (isNotNullOrUndefined(recommendations) && recommendations.length > 0) {
      const fetchData = fetchRecommendations(
        recommendations,
        setPersonalizedData,
        recommendationsApi,
        siteUid
      );

      void fetchData();
    }
  }, [recommendations, recommendationsApi, siteUid, isLoggedIn]);

  useEffect(() => {
    if (contentModules.length === 0 || priceData !== null) {
      return;
    }

    const { isFetchPrioritised, productCodes } =
      createProductFetchInfo(contentModules);
    const gridBanners = contentModules
      .flatMap((banner) => {
        if (banner._type === "TwoColumnGrid") {
          return (banner as TwoColumnGrid).twoColumnGrid;
        }

        if (banner._type === "fourGridBanners") {
          return (banner as FourGridBanners).fourGridBanners;
        }

        return null;
      })
      .filter(isNotNullOrUndefined);

    const columnBanners = contentModules
      .flatMap((banner) =>
        banner._type === "TwoColumnBanners"
          ? (banner as TwoColumnBanners).twoColumnBanners
          : null
      )
      .filter(isNotNullOrUndefined);

    const fetchAndSetProducts = () => {
      const fetch = async () => {
        const priceDisplays = await fetchPriceDisplays(productCodes, pricesApi);
        setPriceData(
          convertToBannerPricing(priceDisplays, t, isLoggedIn, siteUid, [
            ...gridBanners,
            ...columnBanners,
          ])
        );
      };
      void fetch();
    };

    if (isFetchPrioritised) {
      try {
        fetchAndSetProducts();

        document.removeEventListener("scroll", fetchAndSetProducts);
        return;
      } catch (error) {
        log.error(error);
        return;
      }
    }

    if (productCodes.length === 0) {
      return;
    }

    document.addEventListener("scroll", fetchAndSetProducts);

    return () => document.removeEventListener("scroll", fetchAndSetProducts);
  }, [contentModules, isLoggedIn, priceData, pricesApi, siteUid, t]);

  return isNotNullOrUndefined(contentModules) ? (
    <AllComponentsWrapper>
      {contentModules.map((module, index) => {
        const moduleType = module._type;

        if (moduleType === MODULE_TYPES.BRANDS && "brandsComponent" in module) {
          const brands =
            isNotNullOrUndefined(module.brandsComponent) &&
            "brands" in module.brandsComponent &&
            setBrands(
              module.brandsComponent,
              breakpoint as BrandsMaxAmountProps
            );
          return brands !== false &&
            isNotNullOrUndefined(brands) &&
            brands.length > 0 ? (
            <ErrorBoundary key={index}>
              <SiteContainer hideBottomPadding={true}>
                <ComponentWrapper>
                  <BrandList brands={brands} isFullWidth={true} />
                </ComponentWrapper>
              </SiteContainer>
            </ErrorBoundary>
          ) : null;
        }

        if (moduleType === MODULE_TYPES.FULL_GRID) {
          const { fullGridBanners } = module as FullGridComponent;

          if (!isNotNullOrUndefined(fullGridBanners)) {
            return null;
          }

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary key={index}>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    <FullGridBanner
                      content={fullGridBanners}
                      positionInHomepage={index}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }
        if (moduleType === MODULE_TYPES.USPS && "uspTeasers" in module) {
          const usps = module.uspTeasers ?? null;

          if (isNotNullOrUndefined(usps) && usps.length > 0) {
            const filteredUsps = setUsps(
              usps,
              breakpoint as UspsMaxAmountProps
            );
            return (
              <ErrorBoundary key={index}>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    <UspComponent usps={filteredUsps} />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            );
          }
        }

        if (moduleType === MODULE_TYPES.GUIDES && "guides" in module) {
          const guides = module.guides ?? null;

          if (isNotNullOrUndefined(guides) && guides.length > 0) {
            return (
              <ErrorBoundary key={index}>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    {"title" in module &&
                      isNotNullOrUndefined(module.title) && (
                        <Heading>{module.title}</Heading>
                      )}
                    <XxlStack mt={componentSpacing} />
                    <Guides guides={guides} />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            );
          }
        }

        if (moduleType === MODULE_TYPES.CONTENT_PRODUCT_CAROUSEL) {
          const moduleData = module as ContentProductCarousel &
            InternalIdentifier;
          const key = moduleData._key;
          const recommendation = personalizedData?.find(
            (item) => item.key === `${CONTENT_PRODUCT_CAROUSEL_PREFIX}${key}`
          );

          return (
            <ErrorBoundary key={index}>
              <ComponentWrapper>
                <ContentProductCarouselComponent
                  showLoadingStatus={true}
                  personalizedData={
                    isNotNullOrUndefined(recommendation)
                      ? recommendation
                      : { key: key, numFound: 0 }
                  }
                  contentData={moduleData}
                />
              </ComponentWrapper>
            </ErrorBoundary>
          );
        }
        if (
          moduleType === MODULE_TYPES.RECOMMENDATIONS &&
          "strategy" in module
        ) {
          const key = "_key" in module && module._key;
          const recommendation =
            isNotNullOrUndefined(key) &&
            personalizedData?.find((item) => item.key === key);

          const hasRecommendation =
            isNotNullOrUndefined(recommendation) &&
            recommendation !== false &&
            isNotNullOrUndefined(recommendation.productList) &&
            recommendation.productList.length > 0;
          return (
            <ErrorBoundary key={index}>
              <ComponentWrapper>
                <ProductList
                  showLoadingStatus={true}
                  listStyle={
                    isNotNullOrUndefined(module.listStyle)
                      ? (module.listStyle.toUpperCase() as ListStyles)
                      : ListStyles.HORIZONTAL
                  }
                  productsCount={module.productsCount ?? DEFAULT_PRODUCTS_COUNT}
                  recommendationsKey={module._key}
                  strategy={
                    module.strategy as RecommendationsParametersStrategyEnum
                  }
                  products={hasRecommendation ? recommendation.productList : []}
                  carouselType={
                    module.strategy as RecommendationsParametersStrategyEnum
                  }
                  linkUrl={module.recommendationsLink?.url}
                  linkDisplayName={module.recommendationsLink?.displayName}
                  days={module.days}
                  title={module.title}
                />
              </ComponentWrapper>
            </ErrorBoundary>
          );
        }
        if (module._type === MODULE_TYPES.MINI_BANNER) {
          const moduleData = module as MiniBannerType;
          const bannerData: MiniBannerNonOptional = {
            ...moduleData,
            backgroundImage:
              isNotNullOrUndefined(moduleData.backgroundImage) &&
              isNotNullOrUndefined(moduleData.backgroundImage.url)
                ? {
                    baseUrl: moduleData.backgroundImage.url,
                  }
                : null,
          } as MiniBannerNonOptional;
          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    <MiniBanner bannerData={bannerData} />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }
        if (module._type === MODULE_TYPES.CATEGORIES) {
          const moduleData = module as HomePageCategories;
          return (
            <ErrorBoundary key={index}>
              <SiteContainer hideBottomPadding={true}>
                <ComponentWrapper>
                  <PopularCategories
                    title={moduleData.title}
                    level1Categories={moduleData.level1Categories}
                  />
                </ComponentWrapper>
              </SiteContainer>
            </ErrorBoundary>
          );
        }
        if (module._type === MODULE_TYPES.HIGHLIGHTED_CATEGORIES) {
          const moduleData = module as HighlightedCategoriesComponent;
          return (
            <ErrorBoundary key={index}>
              <SiteContainer hideBottomPadding={true}>
                <ComponentWrapper>
                  <HighlightedCategories
                    title={moduleData.title}
                    level1Categories={moduleData.level1Categories}
                  />
                </ComponentWrapper>
              </SiteContainer>
            </ErrorBoundary>
          );
        }

        if (module._type === MODULE_TYPES.HALF_GRID) {
          const { halfGridBanners } = module as HalfGridComponent;

          if (halfGridBanners === undefined) {
            log.error("Half Grid Banner is undefined.");
            return null;
          }

          if (halfGridBanners.length !== 2) {
            log.error(
              `The Half Grid components expects 2 banners but got ${halfGridBanners.length}.`
            );
            return null;
          }
          const [first, second] = halfGridBanners;

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    <HalfGridBanner
                      firstBanner={first}
                      secondBanner={second}
                      positionInHomepage={index}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }

        if (module._type === MODULE_TYPES.CAMPAIGN_CAROUSEL) {
          return (
            <ErrorBoundary key={index}>
              <ComponentWrapper>
                <CampaignCarouselComponent
                  key={index}
                  module={module as CampaignCarouselType}
                />
              </ComponentWrapper>
            </ErrorBoundary>
          );
        }

        if (module._type === MODULE_TYPES.QUARTER_GRID) {
          const {
            halfGridBanners,
            verticalGridBanners,
            horizontalGridBanners,
          } = module as QuarterGridComponent;

          if (halfGridBanners.length !== 1) {
            log.error(
              `Expected Half grid banner count to be 1, got ${halfGridBanners.length}.`
            );
            return null;
          }

          if (verticalGridBanners.length !== 1) {
            log.error(
              `Expected Vertical grid banner count to be 1, got ${halfGridBanners.length}.`
            );
            return null;
          }

          if (horizontalGridBanners.length !== 2) {
            log.error(
              `Expected Horizontal grid banner count to be 2 got ${halfGridBanners.length}.`
            );
            return null;
          }

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary>
                <SiteContainer hideBottomPadding={true}>
                  <ComponentWrapper>
                    <QuarterGridBanner
                      positionInHomepage={index}
                      firstBanner={halfGridBanners[0]}
                      secondBanner={verticalGridBanners[0]}
                      thirdBanner={horizontalGridBanners[0]}
                      fourthBanner={horizontalGridBanners[1]}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }

        if (module._type === MODULE_TYPES.MEDIUM_BANNER) {
          const { twoColumnGrid } = module as TwoColumnGrid;

          if (!isNotNullOrUndefined(twoColumnGrid)) {
            log.error("Two column grid banner is not defined.");
            return null;
          }

          if (twoColumnGrid.length !== 2) {
            log.error(
              `Two column grid banner has an unexpected length: ${twoColumnGrid.length}`
            );
            return null;
          }

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary key={index}>
                <SiteContainer hideBottomPadding={true} hasPadding={true}>
                  <ComponentWrapper>
                    <LegacyBanners
                      banners={twoColumnGrid}
                      size={BannerSize.MEDIUM}
                      priceData={priceData ?? []}
                      positionInHomepage={index}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }

        if (module._type === MODULE_TYPES.SMALL) {
          const { twoColumnBanners } = module as TwoColumnBanners;

          if (!isNotNullOrUndefined(twoColumnBanners)) {
            log.error("Two column grid banner is not defined.");
            return null;
          }

          if (twoColumnBanners.length !== 2) {
            log.error(
              `Two column grid banner has an unexpected length: ${twoColumnBanners.length}`
            );
            return null;
          }

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary key={index}>
                <SiteContainer hideBottomPadding={true} hasPadding={true}>
                  <ComponentWrapper>
                    <LegacyBanners
                      banners={twoColumnBanners}
                      size={BannerSize.SMALL}
                      priceData={priceData ?? []}
                      positionInHomepage={index}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }

        if (module._type === MODULE_TYPES.XS_BANNER) {
          const { fourGridBanners } = module as FourGridBanners;

          if (!isNotNullOrUndefined(fourGridBanners)) {
            log.error("Four grid grid banner is not defined.");
            return null;
          }

          if (fourGridBanners.length !== 4) {
            log.error(
              `Four column banner has unexpected length: ${fourGridBanners.length}`
            );
            return null;
          }

          return (
            <BannerComponentWrapper key={index}>
              <ErrorBoundary>
                <SiteContainer hideBottomPadding={true} hasPadding={true}>
                  <ComponentWrapper>
                    <LegacyBanners
                      banners={fourGridBanners}
                      size={BannerSize.XS}
                      priceData={priceData ?? []}
                      positionInHomepage={index}
                    />
                  </ComponentWrapper>
                </SiteContainer>
              </ErrorBoundary>
            </BannerComponentWrapper>
          );
        }

        log.debug("Homepage moduletype not recognised: ", module._type);
        return null;
      })}
    </AllComponentsWrapper>
  ) : null;
};
