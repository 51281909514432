import type { HighlightedCategoriesComponent } from "@xxl/content-api";
import type { ArrayOfCategoryData, CategoryData } from "@xxl/pim-api";
import React, { useState, useEffect } from "react";
import { useSharedData } from "../../contexts/SharedData";
import { useApiClients } from "../../contexts/ApiClients";
import {
  Wrapper,
  Heading,
  List,
  Item,
  Link,
  IconBackground,
  CategoryName,
} from "./HighlightedCategories.styled";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { mapCategoriesToDisplayData } from "../PopularCategories/utils";
import { useMediaQuery } from "@mui/material";
import { mobileAndTabletMediaQuery } from "../../utils/xxl-screen";
import { CategoryIcon } from "../MegaMenu/Mobile/CategoryIcon";
import { log } from "@xxl/logging-utils";

export const HighlightedCategories: React.FunctionComponent<
  HighlightedCategoriesComponent
> = ({ title, level1Categories }) => {
  const [categoryIds, setCategoryIds] = useState<string[]>();
  const [categoriesData, setCategoriesData] = useState<ArrayOfCategoryData>();
  const [categoriesDisplayData, setCategoriesDisplayData] =
    useState<CategoryData[]>();
  const { siteUid } = useSharedData().data;
  const { pimApi } = useApiClients();
  const isMobile = useMediaQuery(mobileAndTabletMediaQuery);

  useEffect(() => {
    if (isNotNullOrUndefined(level1Categories) && level1Categories.length > 0) {
      const categoryIdsArray = level1Categories
        .map((item) =>
          isNotNullOrUndefined(item) && isNotNullOrUndefined(item.ecomCode)
            ? item.ecomCode
            : ""
        )
        .filter((item) => item !== "");
      setCategoryIds(categoryIdsArray);
    }
  }, [level1Categories]);

  useEffect(() => {
    const _getCategories = async (categories: string): Promise<void> => {
      try {
        const categoriesResponse = await pimApi.getCategories(
          siteUid,
          categories
        );

        if (categoriesResponse.data.length > 0) {
          setCategoriesData(categoriesResponse.data);
        }
      } catch (err) {
        log.error("Cannot get categories data", err);
      }
    };
    if (isNotNullOrUndefined(categoryIds)) {
      void _getCategories(categoryIds.join(","));
    }
  }, [categoryIds, siteUid]);

  useEffect(() => {
    if (
      isNotNullOrUndefined(categoriesData) &&
      isNotNullOrUndefined(level1Categories)
    ) {
      setCategoriesDisplayData(
        mapCategoriesToDisplayData(categoriesData, level1Categories)
      );
    }
  }, [categoriesData, level1Categories]);

  const categoriesToDisplay = isMobile ? 4 : 6;
  const categoriesWithItemCode = categoriesDisplayData?.filter((category) =>
    isNotNullOrUndefined(category.code)
  );

  return (
    <Wrapper>
      {isNotNullOrUndefined(title) && <Heading>{title}</Heading>}
      {isNotNullOrUndefined(categoriesWithItemCode) &&
        categoriesWithItemCode.length > 0 && (
          <List>
            {categoriesWithItemCode
              .slice(0, categoriesToDisplay)
              .map((item, index) => (
                <Item key={index}>
                  <Link href={item.url}>
                    <IconBackground>
                      <CategoryIcon
                        code={item.code as string}
                        showFallback={true}
                      />
                    </IconBackground>
                    <CategoryName>{item.name}</CategoryName>
                  </Link>
                </Item>
              ))}
          </List>
        )}
    </Wrapper>
  );
};
