import React from "react";
import {
  componentIndicesOnHomepageWithHighPrio,
  getBannerHeights,
  toBannerContentProps,
} from "../Shared/BannerContent/BannerContent.helper";
import type {
  HalfGridBanner,
  HorizontalGridBanner,
  VerticalGridBanner,
} from "@xxl/content-api";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { BannerContent } from "../Shared/BannerContent/BannerContent";
import { Wrapper } from "./QuarterGridBanner.styled";
import { withErrorBoundary } from "../../../utils/WithErrorBoundary/with-error-boundary";

export type QuarterGridBannerProps = {
  firstBanner: HalfGridBanner;
  secondBanner: VerticalGridBanner;
  thirdBanner: HorizontalGridBanner;
  fourthBanner: HorizontalGridBanner;
  positionInHomepage: number;
};

const _QuarterGridBanner = ({
  firstBanner,
  secondBanner,
  thirdBanner,
  fourthBanner,
  positionInHomepage,
}: QuarterGridBannerProps) => {
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const fetchPriority = componentIndicesOnHomepageWithHighPrio.includes(
    positionInHomepage
  )
    ? "high"
    : "low";
  const first = toBannerContentProps({
    banner: firstBanner,
    height: getBannerHeights().quarterHalfGrid,
    variant: "half",
    imageSettings: {
      fetchPriority,
      isMobile,
      sizes: `
        (max-width: 400px) 400w,
        (max-width: 600px) 600w,
        (max-width: 800px) 800w,
        (max-width: 1000px) 1000w,
        (max-width: 1279px) 1279w,
        660w
      `,
      srcSetSizes: [
        { width: 400, size: "400w" },
        { width: 600, size: "600w" },
        { width: 800, size: "800w" },
        { width: 1000, size: "1000w" },
        { width: 1279, size: "1279w" },
        { width: 660, size: "660w" },
      ],
    },
    isLaptop,
    linkVariant: "WITH_LINK_AND_BUTTONS",
  });

  const second = toBannerContentProps({
    banner: secondBanner,
    height: getBannerHeights().quarterVertical,
    variant: "vertical",
    imageSettings: {
      fetchPriority,
      isMobile,
      sizes: `
        (max-width: 400px) 400w,
        (max-width: 600px) 600w,
        (max-width: 800px) 800w,
        (max-width: 1000px) 1000w,
        (max-width: 1279px) 1279w,
        660w
      `,
      srcSetSizes: [
        { width: 400, size: "400w" },
        { width: 600, size: "600w" },
        { width: 800, size: "800w" },
        { width: 1000, size: "1000w" },
        { width: 1279, size: "1279w" },
        { width: 660, size: "660w" },
      ],
    },
    isLaptop,
    linkVariant: "WITH_LINK_AND_BUTTONS",
  });

  const horizontalSizes = `
    (max-width: 400px) 400w,
    (max-width: 600px) 600w,
    (max-width: 800px) 800w,
    (max-width: 1000px) 1000w,
    (max-width: 1279px) 1279w,
    660w
  `;

  const horizontalSrcSetSizes = [
    { width: 400, size: "400w" },
    { width: 600, size: "600w" },
    { width: 800, size: "800w" },
    { width: 1000, size: "1000w" },
    { width: 1279, size: "1279w" },
    { width: 660, size: "660w" },
  ];

  const horizontalHeights = getBannerHeights().quarterHorizontal;
  const horizontalVariant = "horizontal";

  const third = toBannerContentProps({
    banner: thirdBanner,
    height: horizontalHeights,
    variant: horizontalVariant,
    imageSettings: {
      fetchPriority,
      isMobile,
      sizes: horizontalSizes,
      srcSetSizes: horizontalSrcSetSizes,
    },
    isLaptop,
    linkVariant: "WITH_LINK_AND_BUTTONS",
  });

  const fourth = toBannerContentProps({
    banner: fourthBanner,
    height: horizontalHeights,
    variant: horizontalVariant,
    imageSettings: {
      fetchPriority,
      isMobile,
      sizes: horizontalSizes,
      srcSetSizes: horizontalSrcSetSizes,
    },
    isLaptop,
    linkVariant: "WITH_LINK_AND_BUTTONS",
  });

  return (
    <Wrapper data-testid="quarter-grid-component">
      <BannerContent
        {...first}
        isScalable={true}
        style={{ gridArea: "first", overflow: "hidden" }}
        testid="half-grid-banner"
      />
      <BannerContent
        {...second}
        isScalable={true}
        style={{ gridArea: "second", overflow: "hidden" }}
        testid="vertical-grid-banner"
      />
      <BannerContent
        {...third}
        isScalable={true}
        style={{ gridArea: "third", overflow: "hidden" }}
        testid="horizontal-grid-banner"
      />
      <BannerContent
        {...fourth}
        isScalable={true}
        style={{ gridArea: "fourth", overflow: "hidden" }}
      />
    </Wrapper>
  );
};

export const QuarterGridBanner = withErrorBoundary(_QuarterGridBanner);
