import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { MQ } from "../../../styles/helpers";

const sharedVideoStyles = () => css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

type StyledWrapperProps = {
  isContentProductCarousel?: boolean;
};

export const StyledWrapper = styled.li<StyledWrapperProps>(
  ({ isContentProductCarousel = false }) => css`
    padding-top: ${isContentProductCarousel ? "53%" : "56.25%"};
    position: relative;

    ${isContentProductCarousel &&
    css`
      ${MQ("tabletHorizontal")} {
        padding-top: 72%;
      }
    `}
  `
);

export const StyledIFrame = styled.iframe`
  ${sharedVideoStyles};
`;

export const StyledVideo = styled.video`
  ${sharedVideoStyles};
`;
